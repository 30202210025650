import React from 'react';
import Layout from '@components/Layout/LayoutTemplates/InstitutionsLayout';
import SEO from '@components/SEO';
import InstitutionsModule from '@modules/Institutions';

const Institutions = () => (
  <Layout isReleased={true} isMobileComingSoon={false}>
    <SEO title="Institutions" description="Institutions" />
    <div className="institutions-section">
      <InstitutionsModule />
    </div>
  </Layout>
);

export default Institutions;
