import React from 'react';
import FooterBanner from './FooterBanner';
import FooterListsCollection from './FooterListsCollection';
import FooterSponsors from './FooterSponsors';
import FooterCopyright from './FooterCopyright';

const Footer = () => (
  <footer className="footer-container">
    <div className="footer">
      <div className="footer-upper-container">
        <FooterBanner />
        <FooterListsCollection />
      </div>
      <div className="footer-lower-container">
        <FooterCopyright />
        <FooterSponsors />
      </div>
    </div>
  </footer>
);

export default Footer;
