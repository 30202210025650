import React from 'react';
import SpriteIconViewer from '@components/Icons/SpriteIconViewer';

const ComingSoon = () => {
  return (
    <div className={'mobile-coming-soon'}>
      <div className={'coming-soon-text'}>
        <div className={'coming-soon-logo'}>
          <SpriteIconViewer spriteId="wanpng_black" />
        </div>
        <h1>COMING SOON</h1>
        <h2>
          We're working on a new mobile experience for Wan PNG. In the meantime,
          you can still visit our full website on your desktop or laptop.
        </h2>
      </div>
    </div>
  );
};

export default ComingSoon;
