import React from 'react';
import Header from '@components/Layout/Headers/InstitutionsHeader';
import Main from '@components/Layout/Main';
import Footer from '@components/Layout/Footer';
import ComingSoon from '@components/Layout/ComingSoon';
import '@scss/main.scss';

const InstitutionsLayout = ({
  children,
  className,
  isMobileComingSoon = true,
}) => {
  if (isMobileComingSoon) {
    return (
      <>
        <div className="desktop-only">
          <Header />
          <Main className={className}>{children}</Main>
          <Footer />
        </div>

        <div className="mobile-only">
          <ComingSoon />
        </div>
      </>
    );
  }

  return (
    <>
      <div>
        <Header />
        <Main className={className}>{children}</Main>
        <Footer />
      </div>
    </>
  );
};

export default InstitutionsLayout;
